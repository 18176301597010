import React from "react";
import { Grid } from "@material-ui/core";

const LegalPersonForm = (props) => {
  const { handleChangeLegal, legalPerson } = props;
  const {
    socialReason,
    password,
    confirmPassword,
    contact,
    email,
    secondContact,
  } = legalPerson;

  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md xs={6}>
              <input
                type="text"
                name="socialReason"
                value={socialReason}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Raison sociale"
                onChange={handleChangeLegal}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="password"
                value={password}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Mot de passe"
                onChange={handleChangeLegal}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="confirmPassword"
                value={confirmPassword}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Confirmer mot de passe"
                onChange={handleChangeLegal}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="contact"
                value={contact}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="contact"
                onChange={handleChangeLegal}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <input
                type="email"
                name="email"
                value={email}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Email"
                onChange={handleChangeLegal}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="secondContact"
                value={secondContact}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Contact 2"
                onChange={handleChangeLegal}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LegalPersonForm;
