import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import { IoPersonOutline, BsBuilding } from "react-icons/all";

const tabs = [
  {
    label: "Personne morale",
    value: 1,
    icon: <BsBuilding fontSize={30} className="mr-6" />,
  },

  {
    label: "Personne physique",
    value: 2,
    icon: <IoPersonOutline fontSize={30} className="mr-6" />,
  },
];

const SignUpTab = (props) => {
  const { activeTab, handleChangeTab } = props;

  return (
    <div className="tab-manager">
      <Grid container spacing={2} className="flex justify-center">
        {tabs?.map(({ icon, label, value }) => (
          <Grid item md={4} xs={12} key={label}>
            <div
              className={`tab ${value === activeTab ? "selected-tab" : ""}`}
              onClick={() => {
                handleChangeTab(value);
              }}
            >
              {icon}
              {label}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default SignUpTab;

SignUpTab.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};
