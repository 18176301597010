const NavbarConnexionSlide = () => {
  return (
    <div className="s-wrap">
      <div className="s-move">
        <div className="slide">
          <h2 className="text-base mb-8">
            Accédez à vos prévisions d'activité et votre réalisation en un coup
            d'oeil
          </h2>
        </div>
        <div className="slide">
          <h2 className="text-base mb-8">Gérez vos Fournisseurs</h2>
        </div>
        <div className="slide">
          <h2 className="text-base mb-8">
            Gérez vos clients, vos devis, propositions et factures et veillez à
            leur parfaite satisfaction
          </h2>
        </div>
        <div className="slide">
          <h2 className="text-base mb-8">
            Demande de devis, commande, livraison et factures
          </h2>
        </div>
      </div>
    </div>
  );
};

export default NavbarConnexionSlide;
