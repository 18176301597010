import { CrossStorageClient } from "cross-storage";

class CrossStorageService {
  constructor() {
    this._storage = new CrossStorageClient(
      process.env.REACT_APP_CUSTOM_AKILCAB_URL,
      {
        timeout: 30000,
        frameId: "storageFrame",
      }
    );
  }

  /**
   * @returns
   */
  onConnect() {
    return this._storage.onConnect();
  }

  /**
   * @param {*} key
   * @returns
   */
  get(key) {
    return this._storage.get(key);
  }

  /**
   * @param {*} key
   * @param {*} value
   * @param {*} ttl
   * @returns
   */
  set(key, value, ttl) {
    return this._storage.set(key, value, ttl);
  }

  /**
   * @returns
   */
  clear() {
    return this._storage.clear();
  }
}

const storage = new CrossStorageService();
export default storage;
