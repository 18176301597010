import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  makeStyles,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import { DialogMigrate } from "app/shared/components/modal/modal-custom-migration";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paperDialog: {
    width: 440,
  },
  StyleLine: {
    borderBottom: "1px solid black",
    opacity: 0.2,
  },
});

const ModalSelectPofil = (props) => {
  const classes = useStyles();
  const {
    open,
    user,
    handleReturn,
    getCredentials,
    loading,
    setLoading,
    setCredentials,
    gettoken,
  } = props;

  const profiles = getCredentials?.structures;
  const getCurrentUser = (profile, currentIndex) => {
    setCredentials(getCredentials);
    const profile_id = profile && profile.profile_id;
    const index = currentIndex && currentIndex !== undefined ? currentIndex : 0;

    if (getCredentials && !getCredentials?.activeStructure) {
      gettoken(profile_id, index, getCredentials);
      setLoading(true);
    }
  };

  return (
    <DialogMigrate
      aria-labelledby="simple-dialog-title"
      open={open}
      disableBackdropClick
    >
      <div className={classes.paperDialog}>
        <DialogTitle id="simple-dialog-title" className="leading-tight">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div>
              <p className="uppercase">salut {user?.username}</p>
              <span className="font-thin">
                Veuillez sélectionner un compte s'il vous plaît
              </span>
            </div>
            <div>
              {loading ? (
                <CircularProgress size={30} color="secondary" />
              ) : null}
            </div>
          </div>
          <hr className={classes.StyleLine} />
        </DialogTitle>

        <List>
          {profiles?.map((profile, currentIndex) => (
            <ListItem
              button
              onClick={() => getCurrentUser(profile, currentIndex)}
              key={profile.id}
              disabled={loading}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  profile?.accounting_firm?.first_name ||
                  profile?.accounting_firm?.social_reason
                }`}
              />
              <hr className={classes.StyleLine} />
            </ListItem>
          ))}
        </List>
        <div className="text-center mt-20 mb-20">
          <button onClick={handleReturn} disabled={loading}>
            Retournez sur la page d'accueil
          </button>
        </div>
      </div>
    </DialogMigrate>
  );
};

ModalSelectPofil.propTypes = {
  open: PropTypes.bool.isRequired,
};

const Profiles = (props) => {
  const {
    user,
    open,
    getCredentials,
    akilcabService,
    setHeaders,
    gettoken,
    updateCredentials,
    setCredentials,
  } = props;
  const handleReturn = () => props.history.push("/landing");
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <ModalSelectPofil
        open={open}
        gettoken={gettoken}
        handleReturn={handleReturn}
        akilcabService={akilcabService}
        history={props.history}
        getCredentials={getCredentials}
        user={user}
        setLoading={setLoading}
        loading={loading}
        setCredentials={setCredentials}
        setHeaders={setHeaders}
        updateCredentials={updateCredentials}
      />
    </div>
  );
};

export default withRouter(Profiles);
