import AuthService from "../services/auth/auth.service";
import NotificationService from "../services/notification/notification.service";
import { toast } from "react-toastify";

class ErrorInterceptor {
  constructor() {
    this._auth = AuthService;
    this._notificationService = NotificationService;
    this._toastr = toast;
  }

  intercept(response) {
    if (response.status === 422 || response.data.code === 422) {
      const errors = response && response.data;

      if (
        response.status === "INVALID_FIELDS" ||
        response.data.status === "INVALID_FIELDS"
      ) {
        if (response && response.data.length > 0) {
          Object.keys(errors).map((key) => {
            this._toastr.warn(`${errors[key].message}`);
          });
        } else {
          this._toastr.warn(`${errors.message}`);
        }
      } else {
        if (response && response.data.length > 0) {
          Object.keys(errors).map((key) => {
            this._toastr.warn(`${errors[key].message}`);
          });
        } else {
          this._toastr.warn(`${errors.message}`);
        }
      }
    } else if (response.status === 403 || response.data.code === 403) {
      if (response.data.status === "INVALID_TOKEN") {
        this._toastr.warn(`${response.data.message}`);
      } else if (response.data.status === "EXPIRED_TOKEN") {
        this._toastr.warn(`${response.data.message}`);
      }

      this._auth.logout();
    } else if (
      (response.status === 402 || response.data.code === 402) &&
      response.data.status === "PAYMENT_REQUIRED"
    ) {
      this._notificationService.warning(null, `${response.data.message}`);
    } else if (
      (response.status === 401 || response.data.code === 401) &&
      response.data.status === "UNAUTHORIZED_USER"
    ) {
      this._toastr.warn(`${response.data.message}`);

      this._auth.logout();
    } else if (
      (response.status === 500 || response.data.code === 400) &&
      response.data.message === "Expired JWT Token"
    ) {
      this._toastr.warn(`${response.data.message}`);

      this._auth.logout();
    } else if (response.status === 0) {
      this._toastr.warn(`Une connexion lente ou inexistante a été détectée.`);
    }

    response.config.__isRetryRequest = true;
    return Promise.reject({ ...response });
  }
}

const error = new ErrorInterceptor();
export default error;
