import { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";

import ConnexionSlide from "../NavbarConnexionSlide.js";
import SignUpTabContent from "./SignUpTabContent";
import Logo from "app/shared/assets/images/icons/logo.png";

const SignUp = (props) => {
  const [legalPerson, setLegalPerson] = useState({
    socialReason: "",
    confirmPassword: "",
    email: "",
    contact: "",
    password: "",
    secondContact: "",
  });
  const [physicalPerson, setPhysicalPerson] = useState({
    name: "",
    prenom: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });
  const [activeTab, setActiveTab] = useState(1);
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (activeTab === 2) {
      physicalPerson[name] = value;
      setPhysicalPerson({ ...physicalPerson });
    } else {
      legalPerson[name] = value;
      setLegalPerson({ ...legalPerson });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 2) {
      // console.log("[handleSubmit] physicalPerson", physicalPerson);
    } else {
      // console.log("[handleSubmit] legaPerson", legalPerson);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="left-part--mod">
        <Grid container>
          <Grid item md={12} xs={12} className="flex justify-end">
            <Link to="/landing" className="no-underline">
              <button className="returnBouton-style mr-10">
                Retour à l'accueil
              </button>
            </Link>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className="login-connexion">
              <Grid container>
                <Grid item md={12} xs={12}>
                  <div className="login-logo">
                    <img src={Logo} alt="logo" className="pb-8 w-40" />
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="login-description">
                    <p className="text-xl mb-8 leading-none">
                      <strong>Inscrivez-vous</strong> pour accédez à PME Gestion
                      Facile
                    </p>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <p className="text-center">Vous êtes :</p>
                  <SignUpTabContent
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    legalPerson={legalPerson}
                    physicalPerson={physicalPerson}
                    handleChangeValue={handleChangeValue}
                    handleSubmit={handleSubmit}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <p className="text-center mt-6">
                    2020 Akilcab • Tout droit réservé
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      <Hidden xsDown smDown>
        <div className="right-part--mod">
          <div className="bgImageGrille">
            <ConnexionSlide />
          </div>
        </div>
      </Hidden>
    </div>
  );
};
export default withRouter(SignUp);
