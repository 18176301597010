import React from "react";
import { Grid } from "@material-ui/core";

const PhysicalPersonForm = (props) => {
  const { handleChangePhysical, physicalPerson } = props;
  const { name, prenom, email, contact, password, confirmPassword } =
    physicalPerson;

  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="name"
                value={name}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Nom"
                onChange={handleChangePhysical}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="prenom"
                value={prenom}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Prenoms"
                onChange={handleChangePhysical}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <input
                type="email"
                name="email"
                value={email}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Email"
                onChange={handleChangePhysical}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="contact"
                value={contact}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Contact"
                onChange={handleChangePhysical}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="password"
                value={password}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Mot de passe"
                onChange={handleChangePhysical}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <input
                type="text"
                name="confirmPassword"
                value={confirmPassword}
                className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
                placeholder="Confirmer mot de passe"
                onChange={handleChangePhysical}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhysicalPersonForm;
