import { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import ConnexionSlide from "./NavbarConnexionSlide";
import AkilCabService from "app/core/services/akilcab/akilcab.service";
import Profiles from "./Profiles";
import LoginForm from "./LoginForm";

const Login = (props) => {
  const { history } = props;
  const akilcabService = new AkilCabService();
  const [loginForm, setLoginForm] = useState({
    login: "",
    password: "",
  });
  const [rememberValue, setRememberValue] = useState(false);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [getCredentials, setGetCredentials] = useState();

  const setCredentials = (credentials) => {
    localStorage.setItem("credentials", JSON.stringify(credentials));
    sessionStorage.setItem("credentials", JSON.stringify(credentials));
  };
  const handleChangeLoginContent = (e) => {
    const { name, value } = e.target;
    loginForm[name] = value;
    setLoginForm({ ...loginForm });
  };
  const handleCheckboxContent = (e) => {
    setRememberValue(e.target.checked);
  };
  const setHeaders = (currentIndex, token, res) => {
    return {
      "X-Api-Key": `${process.env.REACT_APP_CUSTOM_KEY_APP}`,
      "X-App-Key": `${process.env.REACT_APP_CUSTOM_CODE_APP}`,
      "X-Cabinet-Key": `${res?.structures[currentIndex].id}`,
      "X-Customer-Folder-Key": 0,
      "X-Customer-Key": 0,
      Authorization: `Bearer ${token}`,
    };
  };
  const updateCredentials = (currentIndex, newToken, res) => {
    if (res?.structures && res?.structures.length > 1) {
      const credentials = JSON.parse(localStorage.getItem("credentials"));
      credentials.token = newToken;
      credentials.activeStructure = res?.structures[currentIndex];
      sessionStorage.setItem("credentials", JSON.stringify(credentials));
      localStorage.setItem("credentials", JSON.stringify(credentials));
      localStorage.setItem("pgflogout", false);
      history.push("/accountant/gestion-dossiers/liste");
    } else {
      const credentials = JSON.parse(localStorage.getItem("credentials"));

      credentials.token = newToken;

      credentials.activeStructure = res?.structures[0];
      sessionStorage.setItem("credentials", JSON.stringify(credentials));
      localStorage.setItem("credentials", JSON.stringify(credentials));
      localStorage.setItem("pgflogout", false);
      history.push("/accountant/gestion-dossiers/liste");
    }
  };
  const gettoken = (profile_id, index, credentialsResponses) => {
    akilcabService
      .getTokenProfile(profile_id, {
        headers: setHeaders(
          index,
          credentialsResponses?.token,
          credentialsResponses
        ),
      })
      .then((res) => {
        updateCredentials(index, res.token, credentialsResponses);
      });
  };
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setIsExist(true);
    loginForm["remember"] = rememberValue;

    akilcabService
      .login(loginForm)
      .then((responses) => {
        setUser(responses.user);
        setGetCredentials(responses);

        if (responses && responses?.structures.length > 1) {
          setOpen(true);
          setIsExist(false);
        } else {
          if (responses && !responses?.activeStructure) {
            let selectedStructursIndex = 0;
            gettoken(
              responses?.structures[selectedStructursIndex]?.profile_id,
              selectedStructursIndex,
              responses
            );
            setCredentials(responses);
          }
        }
      })
      .catch((error) => {
        setIsExist(false);
        toast.warn(error);
      });
  };

  return (
    <div className="login-wrapper">
      <Hidden xsDown smDown>
        <div className="right-part">
          <div className="bgImageGrille">
            <ConnexionSlide />
          </div>
        </div>
      </Hidden>

      <div className="left-part">
        <Grid container>
          <Grid item md={12} xs={12} className="flex justify-end">
            <Link to="/landing" className="no-underline">
              <button className="returnBouton-style mr-10">
                Retour à l'accueil
              </button>
            </Link>
          </Grid>

          <Grid item md={12} xs={12}>
            <div className="login-connexion">
              <Grid container>
                <LoginHeader />

                <Grid item md={12} xs={12}>
                  <LoginForm
                    {...{
                      handleSubmitLogin,
                      handleChangeLoginContent,
                      handleCheckboxContent,
                      isExist,
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <p className="text-center mt-6">
                    Nouveau sur AkilCab ?
                    <Link
                      to="/signup"
                      className="font-medium ml-1 no-underline"
                    >
                      Inscrivez-Vous
                    </Link>
                  </p>
                  <p className="text-center mt-6">
                    2020 Akilcab • Tout droit réservé
                  </p>
                </Grid>

                <Profiles
                  {...{
                    user,
                    open,
                    gettoken,
                    setHeaders,
                    akilcabService,
                    getCredentials,
                    setCredentials,
                    updateCredentials,
                  }}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(Login);
