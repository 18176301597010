import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

import SignUpTab from "./SignUpTab.js";
import LegalPersonForm from "./LegalPersonForm.js";
import PhysicalPersonForm from "./PhysicalPersonForm.js";

const SignUpTabContent = (props) => {
  const {
    activeTab,
    setActiveTab,
    legalPerson,
    physicalPerson,
    handleChangeValue,
    handleSubmit,
  } = props;

  return (
    <div className="App">
      <SignUpTab activeTab={activeTab} handleChangeTab={setActiveTab} />

      <Grid container>
        <Grid item md={12} xs={12}>
          <form id="submit">
            {activeTab === 1 ? (
              <LegalPersonForm
                legalPerson={legalPerson}
                handleChangeLegal={handleChangeValue}
              />
            ) : (
              <PhysicalPersonForm
                physicalPerson={physicalPerson}
                handleChangePhysical={handleChangeValue}
              />
            )}
          </form>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container alignItems="baseline">
            <Grid item md={6} xs={12}>
              <p className="text-center float-left pt-2">
                Déjà un compte ?
                <Link
                  to="/login"
                  className="font-medium text-blue-600 hover:text-blue-500 ml-1 no-underline"
                >
                  Connectez-Vous
                </Link>
              </p>
            </Grid>
            <Grid item md={6} xs={12} className="flex justify-end">
              <button
                type="submit"
                className="submitStyle--mod mt-5"
                onClick={handleSubmit}
              >
                Inscription
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default SignUpTabContent;
