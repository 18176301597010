import React, { useState, useEffect } from "react";
import { useField } from "formik";
import DateFnsUtils from "@date-io/date-fns";

import { Grid } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { renderDateFormat } from "app/shared/components/CustomSharedComponent";
import { FORMAT_DATE_MOMENT } from "app/core/utils/default-constant";

export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);
  const dateFormat = (date) => renderDateFormat(date, FORMAT_DATE_MOMENT);
  const verifyISODateStringDate = (date) =>
    renderDateFormat(date, FORMAT_DATE_MOMENT);

  useEffect(() => {
    if (value) {
      const ISODateString = dateFormat(value);
      setSelectedDate(ISODateString);
    }
  }, [value]);

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = verifyISODateStringDate(date.toISOString());
        setValue(ISODateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...field}
          {...props}
          color="secondary"
          variant="inline"
          inputVariant="outlined"
          value={selectedDate}
          onChange={_onChange}
          invalidDateMessage="Veuillez entrer une date au format correct."
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
