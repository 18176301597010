import AuthService from "../services/auth/auth.service";

let _credentials = null;
let _token = null;
let cabinetId = null;
let customerId = 0;
let customerFolderId = null;

class JwtInterceptor {
  authenticated() {
    if (AuthService.authenticated()) {
      _credentials = AuthService.credentials();
    }
  }
  setHeaders(credentials) {
    if (credentials) {
      _token = credentials.token || null;

      if (credentials.activeStructure) {
        if (credentials.activeStructure.is_cabinet) {
          cabinetId =
            credentials.activeStructure.id || credentials.structures[0].id || 0;
          customerFolderId =
            sessionStorage.getItem("x-customer-folder-key") ||
            localStorage.getItem("x-customer-folder-key") ||
            0;
        } else {
          customerId =
            credentials.activeStructure.id || credentials.structures[0].id || 0;
          cabinetId =
            credentials.activeStructure.accounting_firm ||
            credentials.activeStructure.accounting_firm.id ||
            credentials.structures[0].accounting_firm.id ||
            0;
          customerFolderId = customerId;
        }
      }
    }
  }

  successRequest(request) {
    this.setHeaders(_credentials);
    if (_token) {
      request.headers["X-Api-Key"] = `${process.env.REACT_APP_CUSTOM_KEY_APP}`;
      request.headers["X-App-Key"] = `${process.env.REACT_APP_CUSTOM_CODE_APP}`;
      request.headers["X-Cabinet-Key"] = `${cabinetId}`;
      request.headers["X-Customer-Key"] = `${customerId}`;
      request.headers["X-Customer-Folder-Key"] = `${customerFolderId}`;
      request.headers.Authorization = `Bearer ${_token}`;
    }
    this.setLoginHeader(request);

    return request;
  }

  setLoginHeader(request) {
    if (!_token) {
      request.headers["X-Api-Key"] = `${process.env.REACT_APP_CUSTOM_KEY_APP}`;
    }
  }
}

const jwt = new JwtInterceptor();
export default jwt;
