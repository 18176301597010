import promise from "promise";
import ApiPgfService from "app/core/services/api/api-pgf/api-pgf.service";

export default class SellService {
  constructor() {
    this._api = new ApiPgfService();
    this.endPoint = {
      provider: "providers",
      client: "clients",

      quotes: "quotes",
      commands: "commands",
      delivery: "delivery-notes",
      invoices: "invoices",
      advances: "advances",
      credits: "credits",

      loans: "loans",
      capitalContibution: "capital-contributions",
      ccaContribution: "cca-contributions",
      grants: "grants",
    };
  }

  /**
   * Quotes module
   *
   */

  postQuote(data) {
    return this._api
      ._post(`v1/${this.endPoint.quotes}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postQuoteDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.quotes}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putQuoteDraft(quoteDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.quotes}/${quoteDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuote(quoteId) {
    return this._api
      ._get(`v1/${this.endPoint.quotes}/${quoteId}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getHistoriqueQuoteByReference(exerciseId, reference) {
    return this._api
      ._get(
        `v1/${this.endPoint.quotes}/exercise/${exerciseId}/reference-code/${reference}`
      )
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuotes(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.quotes}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putQuote(devisId, data) {
    return this._api
      ._put(`v1/${this.endPoint.quotes}/${devisId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteQuotes(param) {
    return this._api
      ._delete(`v1/${this.endPoint.quotes}/${param}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postQuoteSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.quotes}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postQuoteChangeStatus(status, data) {
    return this._api
      ._post(`v1/${this.endPoint.quotes}/change-status/${status}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getChangeStatusQuotesWithPublic(quoteId, status) {
    return this._api
      ._get(`v1/quotes-public/${quoteId}/change-status/${status}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Commands module
   *
   */

  getCommands(budgetaryExerciceId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.commands}/exercise/${budgetaryExerciceId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCommand(commandId) {
    return this._api
      ._get(`v1/${this.endPoint.commands}/${commandId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCommandSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.commands}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCommandChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.commands}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCommandChangeStatusWithPublic(commandId, newStatus) {
    return this._api
      ._get(`v1/commands-public/${commandId}/change-status/${newStatus}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCommandReferenceHistory(budgetaryExerciceId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.commands}/exercise/${budgetaryExerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCommand(data) {
    return this._api
      ._post(`v1/${this.endPoint.commands}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCommandDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.commands}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCommand(commandeId, data) {
    return this._api
      ._put(`v1/${this.endPoint.commands}/${commandeId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCommandDraft(commandeDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.commands}/${commandeDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteCommand(commandeId) {
    return this._api
      ._delete(`v1/${this.endPoint.commands}/${commandeId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Bon de livraisons module
   *
   */

  getLivraisons(budgetaryExerciceId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.delivery}/exercise/${budgetaryExerciceId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLivraisonsSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.delivery}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLivraisonsChangeStatus(status, data) {
    return this._api
      ._post(`v1/${this.endPoint.delivery}/change-status/${status}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLivraisonsChangeStatusWithPublic(livraisonId, status) {
    return this._api
      ._get(`v1/delivery-notes-public/${livraisonId}/change-status/${status}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLivraisonsHystoryByReference(budgetaryExerciceId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.delivery}/exercise/${budgetaryExerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLivraison(livraisonId) {
    return this._api
      ._get(`v1/${this.endPoint.delivery}/${livraisonId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postLivraison(data) {
    return this._api
      ._post(`v1/${this.endPoint.delivery}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postLivraisonDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.delivery}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putLivraison(livraisonId, data) {
    return this._api
      ._put(`v1/${this.endPoint.delivery}/${livraisonId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putLivraisonDraft(livraisonId, data) {
    return this._api
      ._put(`v1/${this.endPoint.delivery}/${livraisonId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteLivraison(livraisonId) {
    return this._api
      ._delete(`v1/${this.endPoint.delivery}/${livraisonId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * factures module
   *
   */

  getFactures(budgetaryExerciceId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.invoices}/exercise/${budgetaryExerciceId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFacture(factureId) {
    return this._api
      ._get(`v1/${this.endPoint.invoices}/${factureId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFactureSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.invoices}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFactureChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.invoices}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFactureChangeStatusWithPublic(factureId, newStatus) {
    return this._api
      ._get(`v1/invoices-public/${factureId}/change-status/${newStatus}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFactureHistory(budgetaryExerciceId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.invoices}/exercise/${budgetaryExerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postFacture(data) {
    return this._api
      ._post(`v1/${this.endPoint.invoices}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postFactureDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.invoices}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putFacture(factureId, data) {
    return this._api
      ._put(`v1/${this.endPoint.invoices}/${factureId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putFactureDraft(factureId, data) {
    return this._api
      ._put(`v1/${this.endPoint.invoices}/${factureId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteFacture(factureId) {
    return this._api
      ._delete(`v1/${this.endPoint.invoices}/${factureId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInvoiceAddPayment(factureId, data) {
    return this._api
      ._put(`v1/${this.endPoint.invoices}/${factureId}/add-payment`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInvoicesPay(factureId) {
    return this._api
      ._get(`v1/${this.endPoint.invoices}/${factureId}/pay`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get quotes list selected to create an order
   *
   */

  getQuotesSelectedToCreateAnOrder(budgetaryExerciceId) {
    return this._api
      ._get(
        `v1/${this.endPoint.quotes}/exercise/${budgetaryExerciceId}/next-step`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getDeliveryNoteSelectedToCreateAnInvoices(budgetaryExerciceId) {
    return this._api
      ._get(
        `v1/${this.endPoint.delivery}/exercise/${budgetaryExerciceId}/next-step`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCommandsSelectedToCreateBL(budgetaryExerciceId) {
    return this._api
      ._get(
        `v1/${this.endPoint.commands}/exercise/${budgetaryExerciceId}/next-step`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get quotes list selected to create an order
   *
   */

  getCommandPreviewByDevis(devisId) {
    return this._api
      ._get(`v1/${this.endPoint.commands}/preview/from/quote/${devisId}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getDeliveryNotesPreviewByCommand(params, sourceId) {
    return this._api
      ._get(`v1/${this.endPoint.delivery}/preview/from/${params}/${sourceId}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getDeliveryNotesPreviewByCommandWithOutParam(sourceId) {
    return this._api
      ._get(`v1/${this.endPoint.delivery}/preview/from/${sourceId}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getFacturePreviewByDeliveryNotes(params, livraisonId) {
    return this._api
      ._get(
        `v1/${this.endPoint.invoices}/preview/from/${params}/${livraisonId}`
      )
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getProviders(customerFolderId) {
    return this._api
      ._get(`v1/${this.endPoint.provider}/customer-folder/${customerFolderId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Transform item module to another item according to module selected
   *
   */

  getPurchaseOrderAttachedToQuote(devisId) {
    return this._api
      ._get(`v1/${this.endPoint.quotes}/${devisId}/linked-command`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getDeliveryAttachedToPurchaseOrder(commandeId) {
    return this._api
      ._get(`v1/${this.endPoint.commands}/${commandeId}/linked-delivery`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInvoicesAttachedToDelivery(livraisonId) {
    return this._api
      ._get(`v1/${this.endPoint.delivery}/${livraisonId}/linked-invoice`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Global road to use in all apps
   *
   */

  postReferenceCodeEntred(moduleName, data) {
    return this._api
      ._post(`v1/${moduleName}/last-number`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getReferenceCodeIsFirst(moduleName, exerciceId) {
    return this._api
      ._get(`v1/${moduleName}/exercise/${exerciceId}/is-first`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getUsedSystemReferenceCode(moduleName, exerciseId) {
    return this._api
      ._get(`v1/${moduleName}/exercise/${exerciseId}/use-default`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLoanType() {
    return this._api
      ._get(`v1/loan-types`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCapitalContributionType() {
    return this._api
      ._get("v1/capital-contribution-types")
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getGrantType() {
    return this._api
      ._get(`v1/grant-types`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvancesModuleAvailable(module, exercise_id) {
    return this._api
      ._get(
        `v1/${this.endPoint.advances}/${module}/available/exercise/${exercise_id}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditsInvoiceAvailable(exerciseId) {
    return this._api
      ._get(
        `v1/${this.endPoint.credits}/available-invoices/exercise/${exerciseId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Accompte module
   *
   */

  postAdvances(data) {
    return this._api
      ._post(`v1/${this.endPoint.advances}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvances(exerciceId, params) {
    return this._api
      ._get(`v1/${this.endPoint.advances}/exercise/${exerciceId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvance(advanceId) {
    return this._api
      ._get(`v1/${this.endPoint.advances}/${advanceId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putAdvance(data) {
    return this._api
      ._put(`v1/${this.endPoint.advances}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteAdvance(advanceId) {
    return this._api
      ._delete(`v1/${this.endPoint.advances}/${advanceId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvancesReferenceCode(exerciceId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.advances}/exercise/${exerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvanceModuleAvailable(module, exercise_id) {
    return this._api
      ._get(
        `v1/${this.endPoint.advances}/${module}/available/exercise/${exercise_id}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postAdvanceChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.advances}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getAdvancesPay(advanceId) {
    return this._api
      ._get(`v1/${this.endPoint.advances}/${advanceId}/pay`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Avoir module
   *
   */

  postCreditInvoiceWithStatusSaved(data) {
    return this._api
      ._post(`v1/${this.endPoint.credits}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCreditInvoiceWithStatusDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.credits}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCreditInvoiceWithDraft(creditDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.credits}/${creditDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditInvoiceAvailable(exerciseId) {
    return this._api
      ._get(
        `v1/${this.endPoint.credits}/available-invoices/exercise/${exerciseId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditsInvoices(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.credits}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditsInvoicesHistory(exerciseId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.credits}/exercise/${exerciseId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditInvoice(creditId) {
    return this._api
      ._get(`v1/${this.endPoint.credits}/${creditId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCreditInvoice(creditId, data) {
    return this._api
      ._put(`v1/${this.endPoint.credits}/${creditId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteCreditInvoice(creditId) {
    return this._api
      ._delete(`v1/${this.endPoint.credits}/${creditId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCreditInvoiceChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.credits}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCreditInvoiceSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.credits}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCreditsPay(creditId) {
    return this._api
      ._get(`v1/${this.endPoint.credits}/${creditId}/pay`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Loans
   */

  getLoans(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.loans}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLoan(loadId) {
    return this._api
      ._get(`v1/${this.endPoint.loans}/${loadId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getLoansChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.loans}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postSendLoanByEmail(data) {
    return this._api
      ._post(`v1/${this.endPoint.loans}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postLoanDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.loans}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postLoan(data) {
    return this._api
      ._post(`v1/${this.endPoint.loans}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putLoanDraft(loadDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.loans}/${loadDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putLoan(loanId, data) {
    return this._api
      ._put(`v1/${this.endPoint.loans}/${loanId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteLoan(loadId) {
    return this._api
      ._delete(`v1/${this.endPoint.loans}/${loadId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Capital Contributions
   */

  getCapitalContributionStatus(newStatus, data) {
    return this._api
      ._post(
        `v1/${this.endPoint.capitalContibution}/change-status/${newStatus}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCapitalContributionMail(data) {
    return this._api
      ._post(`v1/${this.endPoint.capitalContibution}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteCapitalContribution(capitalId) {
    return this._api
      ._delete(`v1/${this.endPoint.capitalContibution}/${capitalId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCapitalContribution(capitalId) {
    return this._api
      ._get(`v1/${this.endPoint.capitalContibution}/${capitalId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCapitalContribution(capitalId, data) {
    return this._api
      ._put(`v1/${this.endPoint.capitalContibution}/${capitalId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCapitalContributions(exerciseId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.capitalContibution}/exercise/${exerciseId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCapitalContributionWithDraft(capitalId, params) {
    return this._api
      ._put(`v1/${this.endPoint.capitalContibution}/${capitalId}/draft`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCapitalContributionWithDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.capitalContibution}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCapitalContribution(data) {
    return this._api
      ._post(`v1/${this.endPoint.capitalContibution}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Cca contributions
   */

  postCCAContribution(data) {
    return this._api
      ._post(`v1/${this.endPoint.ccaContribution}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCCAContributionWithDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.ccaContribution}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCCAContributionWithDraft(ccaContributionId, data) {
    return this._api
      ._put(
        `v1/${this.endPoint.ccaContribution}/${ccaContributionId}/draft`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCCAContributions(exerciseId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.ccaContribution}/exercise/${exerciseId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCCAContribution(ccaContributionId) {
    return this._api
      ._get(`v1/${this.endPoint.ccaContribution}/${ccaContributionId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putCCAContribution(ccaContributionId, data) {
    return this._api
      ._put(`v1/${this.endPoint.ccaContribution}/${ccaContributionId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCCAContributionStatus(newStatus, data) {
    return this._api
      ._post(
        `v1/${this.endPoint.ccaContribution}/change-status/${newStatus}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postCCAContributionMail(data) {
    return this._api
      ._post(`v1/${this.endPoint.ccaContribution}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteCCAContribution(ccaContributionId) {
    return this._api
      ._delete(`v1/${this.endPoint.ccaContribution}/${ccaContributionId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Grants
   */

  postGrantSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.grants}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postGrantsChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.grants}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteGrant(grantId) {
    return this._api
      ._delete(`v1/${this.endPoint.grants}/${grantId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putGrant(grantId, data) {
    return this._api
      ._put(`v1/${this.endPoint.grants}/${grantId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getGrants(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.grants}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getGrant(grantId) {
    return this._api
      ._get(`v1/${this.endPoint.grants}/${grantId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putGrantWithDraft(grantId, data) {
    return this._api
      ._put(`v1/${this.endPoint.grants}/${grantId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postGrantWithDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.grants}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postGrant(data) {
    return this._api
      ._post(`v1/${this.endPoint.grants}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  getClients(customerId) {
    return this._api
      ._get(`v1/${this.endPoint.client}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
