import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "@material-ui/core";

import Login from "./pages/landing/navbar/Connexion/Login";
import Profiles from "./pages/landing/navbar/Connexion/Profiles";
import Errors from "./pages/errors/Errors";
import NotificationEmail from "./pages/Notification-email/NotificationEmail";
import ProviderForm from "./pages/Provider-form/ProviderForm";
import SignUp from "./pages/landing/navbar/Connexion/SignUpContent/SignUp";

import THEME from "./theme/index";
import { UserCredentialsProvider } from "./contexts/UserCredentials";

const Landing = React.lazy(() => import("./pages/landing/Landing"));
const Accountant = React.lazy(() => import("./pages/accountant/Accountant"));

toast.configure({
  autoClose: 8000,
  draggable: false,
  hideProgressBar: true,
  closeButton: false,
  pauseOnFocusLoss: true,
  style: { fontSize: 13 },
});

const App = () => {
  return (
    <ThemeProvider theme={THEME}>
      <UserCredentialsProvider>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/landing" />
            <Route
              path="/landing"
              render={() => (
                <Suspense
                  fallback={
                    <div className="loader-wrapper">
                      <div className="loader"></div>
                    </div>
                  }
                >
                  <Landing />
                </Suspense>
              )}
            />
            <Route
              path="/accountant"
              render={() => (
                <Suspense
                  fallback={
                    <div className="loader-wrapper">
                      <div className="loader"></div>
                    </div>
                  }
                >
                  <Accountant />
                </Suspense>
              )}
            />

            <Route path="/login" component={Login} />
            <Route
              path="/notification-email/:moduleName/:moduleNameId/:status"
              component={NotificationEmail}
            />
            <Route
              path="/demande-devis/:providerId/soumission"
              component={ProviderForm}
            />
            <Route path="/signup" component={SignUp} />
            <Route path="/profiles" component={Profiles} />
            <Route path="*">
              <Errors />
            </Route>
          </Switch>
        </Router>
      </UserCredentialsProvider>
    </ThemeProvider>
  );
};

export default App;
