import axios from "axios";

import JwtInterceptor from "app/core/interceptors/jwt-interceptor";
import ErrorInterceptor from "app/core/interceptors/error-interceptor";
import AuthService from "../../auth/auth.service";

export default class ApiPgfService {
  constructor() {
    this._api = axios.create();
    this._url = process.env.REACT_APP_CUSTOM_SERVER_API;

    AuthService.init();
    JwtInterceptor.authenticated();

    this._api.interceptors.request.use((request) =>
      JwtInterceptor.successRequest(request)
    );

    this._api.interceptors.response.use(
      (response) => response,
      (error) => ErrorInterceptor.intercept(error.response)
    );
  }

  /**
   * @param {*} endpoint
   * @param {*} params
   * @param {*} reqOpts
   */
  _get(endpoint, params, reqOpts) {
    if (!reqOpts) {
      reqOpts = {
        params: params,
      };
    }

    return this._api.get(`${this._url}/${endpoint}`, reqOpts);
  }

  /**
   * @param {*} endpoint
   * @param {*} body
   * @param {*} reqOpts
   */
  _post(endpoint, body, reqOpts) {
    return this._api.post(`${this._url}/${endpoint}`, body, reqOpts);
  }

  /**
   * @param {*} endpoint
   * @param {*} body
   * @param {*} reqOpts
   */
  _put(endpoint, body, reqOpts) {
    return this._api.put(`${this._url}/${endpoint}`, body, reqOpts);
  }

  /**
   * @param {*} endpoint
   * @param {*} reqOpts
   */
  _delete(endpoint, reqOpts) {
    return this._api.delete(`${this._url}/${endpoint}`, reqOpts);
  }
}
