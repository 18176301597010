import { colors, createTheme } from "@material-ui/core";
import shadows from "./shadows";

const THEME = createTheme({
  typography: {
    fontSize: 17,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontFamily: `"Mada", "sans-serif"`,
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#F7F7FA",
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  overrides: {
    MuiFormLabel: {
      asterisk: {
        fontSize: "1.2em",
        "&$error": {
          color: "#DC2626",
        },
        color: "#DC2626",
      },
    },
  },
});

export default THEME;
