import CrossStorageService from "../cross-storage/crossStorage.service";

const credentialsKey = "credentials";
const _redirectUrl = `${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;

class AuthService {
  constructor() {
    this._savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);

    if (this._savedCredentials) {
      this._credentials = JSON.parse(this._savedCredentials);
    }
  }

  init() {
    this._savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);

    if (this._savedCredentials) {
      this._credentials = JSON.parse(this._savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  authenticated() {
    return !!this._credentials ? true : false;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  credentials() {
    return this._credentials;
  }

  /**
   * Check if user is Accountant
   * @returns {boolean}
   */
  userIsAccountant() {
    const credentials = this._credentials && this._credentials.credentials;
    return (
      !!credentials.activeStructure.is_cabinet ||
      !!credentials.structures[0].is_cabinet
    );
  }

  /**
   * @param {*} credentials
   */
  setAuthenticatedUser(credentials) {
    this.setCredentials(credentials);
  }

  /**
   * redirect url
   */
  redirectUrl() {
    window.location.href = _redirectUrl;
  }

  /**
   * @returns
   */
  getCredentials() {
    CrossStorageService.onConnect()
      .then(function () {
        return CrossStorageService.get("akilCabCredentials");
      })
      .then(function (res) {
        return JSON.parse(res);
      });
  }

  clearCredentials() {
    CrossStorageService.onConnect()
      .then(() => CrossStorageService.get("akilCabCredentials"))
      .then(() => CrossStorageService.clear());
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout() {
    this.setCredentials(null);
    window.location.href = `${process.env.REACT_APP_CUSTOM_AKILCAB_URL}/#/logout/redirection?return_to=${process.env.REACT_APP_CUSTOM_REDIRECTBACK_URL}/landing`;
    return true;
  }

  /**
   * @param {*} data
   */
  setLocalCredentials(data) {
    if (data && data.credentials) {
      const credentials = data.credentials;
      const localCredentials = credentials;
      this.setCredentials(localCredentials);
    }
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  setCredentials(credentials) {
    this._credentials = credentials || null;

    const parseCredentials =
      credentials && credentials.credentials
        ? credentials.credentials
        : credentials;

    if (parseCredentials) {
      sessionStorage.setItem(credentialsKey, JSON.stringify(parseCredentials));
      localStorage.setItem(credentialsKey, JSON.stringify(parseCredentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}

const auth = new AuthService();
export default auth;
