import promise from "promise";
import ApiService from "../api/akilcab/api.service";

export default class AkilcabService {
  constructor() {
    this._api = new ApiService();
    this.endpoint = "akilcab";
  }

  /**
   *  Get all application list
   *  @param params
   *
   */
  getApplications(params) {
    return this._api
      ._get(`applications`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get all countries
   */

  getCountries() {
    return this._api
      ._get(`countries`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   *  @param countrieId
   */

  getCountriesById(countrieId) {
    return this._api
      ._get(`countries/${countrieId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
  get manager
   */

  getManagers() {
    return this._api
      ._get(`managers`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  postManagers(data) {
    return this._api
      ._post(`managers`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putManagers(clientId, data) {
    return this._api
      ._put(`managers/${clientId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /*
  post social information
  */
  postSocialInformationAkil(data) {
    return this._api
      ._post(`social-informations`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putSocialInformationAkil(clientId, data) {
    return this._api
      ._put(`social-informations/${clientId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get All company type
   */
  getCompanyTypes() {
    return this._api
      ._get(`company-types`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  getCompanyTypesById(companyId) {
    return this._api
      ._get(`company-types/${companyId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get Accountant systems
   */
  getAccountantSystems() {
    return this._api
      ._get(`accountant-systems`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  getAccountantSystemsById(accountantSystemId) {
    return this._api
      ._get(`accountant-systems/${accountantSystemId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get activities by sector
   * @param {*} selectId
   */
  getActivitiesBySector(sectorId) {
    return this._api
      ._get(`accountant-activities/sectors/${sectorId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   *
   * @param activity_id
   */
  getActivity(activity_id) {
    return this._api
      ._get(`accountant-activities/${activity_id}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get accountant activities by country
   * @param countrId
   */
  getAccountantActivities(countryId) {
    return this._api
      ._get(`countries/${countryId}/accountant-activities/sectors`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  getAccountantActivityAndSectorActivity(accountantActivity) {
    return this._api
      ._get(`accountant-activities/${accountantActivity}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Gettaxt systems by country
   * @param countries
   */
  getTaxSystems(countryId) {
    return this._api
      ._get(`countries/${countryId}/tax-systems`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get regional direction by country
   * @param countryId
   */
  getRegionalDirection(countryId) {
    return this._api
      ._get(`countries/${countryId}/regional-directions`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get tax service by regional direction
   * @param regional_direction_id
   */
  getTaxServices(regional_direction_id) {
    return this._api
      ._get(`regional-directions/${regional_direction_id}/tax-services`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   *
   * @param {*} data
   */
  postFiscalFolder(data) {
    return this._api
      ._post(`fiscal-folders`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putFiscalFolder(clientId, data) {
    return this._api
      ._put(`fiscal-folders/${clientId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  /**
   *
   * @param {*} fiscaleId
   */
  getFiscalFolder(fiscaleId) {
    return this._api
      ._get(`fiscal-folders/${fiscaleId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get banks by country
   * @param {*}  countryId
   */
  getBanks(countryId) {
    return this._api
      ._get(`countries/${countryId}/banks`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  /**
   * Set Username and password
   * @param {*}  data
   */
  login(data) {
    return this._api
      ._post(`accounts/login`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Get profile token
   * @param profileId
   */
  getTokenProfile(profileId, headers) {
    return this._api
      ._get(`profiles/${profileId}/token`, "", headers)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Post contact
   * @param data
   */
  postContactInfo(data) {
    return this._api
      ._post("public/contacts", data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Post contact
   * @param data
   */
  postCustomerFolderFile(data) {
    return this._api
      ._post("customers-folders/imports", data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  /**
   * Post contact
   * @param data
   * @param fileId
   */
  getJobListCustomerFolderFile(fileId, data) {
    return this._api
      ._get(`customers-folders/imports/jobs/${fileId}/rows/all`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Post contact
   * @param data
   * @param jobId
   */
  putValideCustomerFolderFile(jobId, data) {
    return this._api
      ._put(`customers-folders/imports/${jobId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Post contact
   * @param data
   *
   */
  getJobList() {
    return this._api
      ._get(`customers-folders/imports`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * subscribe for pgf app from akilcab
   * @param param
   * @param data
   *
   */
  subscribePgfApp(param, data) {
    return this._api
      ._put(`applications/customer-folder/${param}/subscribe`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
