import React from "react";
import { Formik, Form, FieldArray } from "formik";
import { toast } from "react-toastify";

import {
  Grid,
  withStyles,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  makeStyles,
  Select,
  Button,
  IconButton,
  Tooltip,
  Box,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import {
  Close,
  ExpandMore,
  PlaylistAdd,
  Remove,
  Add,
} from "@material-ui/icons";

import {
  QUANTITY,
  PRICE,
  DISCOUNT,
  PAIEMENT_MODE,
  FORMAT_DATE_MOMENT,
} from "app/core/utils/default-constant";
import LoadingImport from "app/shared/assets/images/icons/spinner.gif";
import useRouter from "app/hooks/useRouter";
import BuyService from "app/core/services/budgetary-exercise/j-achete/j-achete.service";
import DatePickerField from "app/shared/components/input/DatePickerField";
import { renderPaymentMode } from "app/shared/components/CustomSharedComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 300,
  },
  wraaperStycky: {
    position: "sticky",
    top: 0,
    overflowX: "auto",
    height: 580,
    padding: 8,
  },
  paper: {
    padding: 20,
    background: "#ffffff 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 10px #00000029",
    marginBottom: 20,
  },
  control: {
    padding: theme.spacing(2),
  },
  inputBackground: {
    backgroundColor: "#fff",
  },
}));
const CustumAccordionSummary = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "0px 16px",
    borderRadius: "5px 5px 0px 0px",
    minHeight: "0rem !important",
    backgroundColor: "#bdc0da",
    height: 45,
    color: "#50589f",
  },
}))(AccordionSummary);
const CustumAccordionDetails = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "8px 16px 16px",
    backgroundColor: "#f8f8fa",
  },
}))(AccordionDetails);

const initialValues = {
  product: [],
  validityQuoteDate: null,
  deliveryDate: null,
  paymentMethod: null,
};

const ProviderFormSubmission = () => {
  const jachetService = new BuyService();

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(0);
  const [isLoadingSend, setIsLoadingSend] = React.useState(false);
  const [isLoadingRefuse, setIsLoadingRefuse] = React.useState(false);
  const [isloading, setIsLoading] = React.useState(false);
  const [demandeDevis, setDemandeDevis] = React.useState({});
  const [confirmationStep, setConfirmationStep] = React.useState(false);
  const [confirmaton, setConfirmation] = React.useState("");

  const router = useRouter();
  const { providerId } = router.query;

  const handleChangeExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const preventNonNumericalInput = (e) => {
    e = e || window.event;
    const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  };
  const renderingNewlyAddedComponent = (
    values,
    arrayHelpers,
    handleChange,
    classes
  ) => {
    return (
      <>
        {values &&
          values.product !== null &&
          values.product.map((res, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item md={12} xs={12}>
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChangeExpanded(index)}
                >
                  <CustumAccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.heading}>
                      {res.designation}
                    </Typography>
                  </CustumAccordionSummary>

                  <CustumAccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item md xs>
                            <label className="label-color">Article</label>
                            <TextField
                              type="text"
                              fullWidth
                              disabled
                              className={classes.inputBackground}
                              placeholder="Libelle de l'article"
                              variant="outlined"
                              color="secondary"
                              size="small"
                              name={`product[${index}].designation`}
                              value={res.designation}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item md xs>
                            <label className="label-color">
                              Quantité Demandée
                            </label>
                            <Box
                              className="flex"
                              style={{
                                border: "1px solid #bdbdbd",
                                borderRadius: "5%",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Qté"
                                className={`${classes.inputBackground} w-3/6 bg-clip-border boder-l-0 boder-t-0 boder-b-0`}
                                onKeyPress={preventNonNumericalInput}
                                required
                                disabled
                                name={`product[${index}].quantityNumber`}
                                value={res.quantityNumber}
                                onChange={handleChange}
                              />
                              <Select
                                native
                                variant="outlined"
                                required
                                disabled
                                size="small"
                                style={{ height: 35 }}
                                color="secondary"
                                className={`${classes.inputBackground} w-3/6`}
                                name={`product[${index}].quantityType`}
                                value={res.quantityType}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Type
                                </option>

                                {QUANTITY.map((quantity, index) => (
                                  <option value={quantity.value} key={index}>
                                    {quantity.display}
                                  </option>
                                ))}
                              </Select>
                            </Box>
                          </Grid>

                          <Grid item md xs>
                            <label className="label-color">
                              Quantité Disponible
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              color="secondary"
                              className={classes.inputBackground}
                              onKeyPress={preventNonNumericalInput}
                              placeholder="Qté"
                              size="small"
                              fullWidth
                              name={`product[${index}].quantityNumberAvailable`}
                              value={res.quantityNumberAvailable}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item md xs>
                            <label className="label-color">
                              Prix unitaire
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Box
                              className="flex"
                              style={{
                                border: "1px solid #bdbdbd",
                                borderRadius: "5%",
                              }}
                            >
                              <input
                                type="number"
                                required
                                placeholder="PU"
                                className={`${classes.inputBackground} w-4/6 bg-clip-border boder-l-0 boder-t-0 boder-b-0`}
                                name={`product[${index}].unitPriceValue`}
                                value={res.unitPriceValue}
                                onChange={handleChange}
                              />
                              <Select
                                native
                                variant="outlined"
                                required
                                size="small"
                                style={{ height: 35 }}
                                color="secondary"
                                className={`${classes.inputBackground} w-2/6`}
                                name={`product[${index}].unitPriceType`}
                                value={res.unitPriceType}
                                onChange={handleChange}
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Type
                                </option>

                                {PRICE.map((price, index) => (
                                  <option value={price.value} key={index}>
                                    {price.display}
                                  </option>
                                ))}
                              </Select>
                            </Box>
                          </Grid>

                          <Grid item md xs>
                            <label className="label-color">Remise</label>
                            <Box
                              className="flex"
                              style={{
                                border: "1px solid #bdbdbd",
                                borderRadius: "5%",
                              }}
                            >
                              <input
                                type="number"
                                placeholder="Remise"
                                className={`${classes.inputBackground} w-4/6 bg-clip-border boder-l-0 boder-t-0 boder-b-0`}
                                name={`product[${index}].discountValue`}
                                value={res.discountValue}
                                onChange={handleChange}
                              />
                              <Select
                                native
                                variant="outlined"
                                required
                                size="small"
                                className={`${classes.inputBackground} w-2/6`}
                                style={{ height: 35 }}
                                color="secondary"
                                defaultValue={`product[${index}].discountType`}
                                value={res.discountType}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Type
                                </option>

                                {DISCOUNT.map((discount, index) => (
                                  <option value={discount.value} key={index}>
                                    {discount.display}
                                  </option>
                                ))}
                              </Select>
                            </Box>
                          </Grid>

                          <Grid item md xs>
                            <label className="label-color">
                              TVA <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              type="text"
                              onKeyPress={preventNonNumericalInput}
                              variant="outlined"
                              className={classes.inputBackground}
                              color="secondary"
                              size="small"
                              placeholder="Tva"
                              fullWidth
                              name={`product[${index}].tvaRate`}
                              value={res.tvaRate}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          multiline
                          rows={10}
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          className={classes.inputBackground}
                          placeholder="Description de l'article..."
                          name={`product[${index}].description`}
                          value={res.description}
                          onChange={handleChange}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CustumAccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          ))}
      </>
    );
  };
  const registerWithStatusSend = (values, actions) => {
    values.paymentMode = renderPaymentMode(values);

    if (values && !values.paymentMethod)
      return toast.info(
        "Veuillez renseigner le mode de paiement s'il vous plaît."
      );

    if (values.product.length > 0) {
      for (let index in values.product) {
        const unitPriceValue = values.product[index].unitPriceValue;
        const unitPriceType = values.product[index].unitPriceType;
        const quantityNumberAvailable =
          values.product[index].quantityNumberAvailable;
        const discountValue = values.product[index].discountValue;
        const tvaRate = values.product[index].tvaRate;
        const discountType = values.product[index].discountType;

        values.product[index].tvaRate =
          (tvaRate && typeof tvaRate !== undefined) ||
          (tvaRate && typeof tvaRate === "string")
            ? +tvaRate
            : 0;

        values.product[index].quantityNumberAvailable =
          (quantityNumberAvailable &&
            typeof quantityNumberAvailable !== undefined) ||
          (quantityNumberAvailable &&
            typeof quantityNumberAvailable === "string")
            ? +quantityNumberAvailable
            : 0;

        //verification if quantityNumber or unitPriceValue is negative
        if (
          ((unitPriceValue || quantityNumberAvailable) !== null &&
            Math.sign(unitPriceValue) === -1) ||
          Math.sign(quantityNumberAvailable) === -1
        )
          return toast.info(
            "Le champ de saisie de montant et la quantité disponible ne peuvent contenir que des valeurs positives."
          );
        //verification if discountValue or tvaRate is more of 100 or less 0
        if (
          (discountValue || tvaRate) !== null &&
          ((discountValue > 100 &&
            discountType &&
            discountType !== "MONTANT") ||
            tvaRate > 100 ||
            Math.sign(discountValue) === -1 ||
            Math.sign(tvaRate) === -1)
        )
          return toast.info(
            "Les champs de saisie de pourcentage ne peuvent contenir que des valeurs positives entre 0 et 100"
          );

        if (!quantityNumberAvailable || quantityNumberAvailable == "") {
          return toast.info(
            "Veuillez renseigner la quantité disponible s'il vous plaît."
          );
        }
        if (
          !unitPriceValue ||
          unitPriceValue == "" ||
          !unitPriceType ||
          unitPriceType == ""
        ) {
          return toast.info(
            "Veuillez renseigner le prix unitaire puis sélectionner son type s'il vous plaît."
          );
        }
      }
    }
    if (providerId) {
      const copyProducts = [...values.product];
      const products = copyProducts.map((product) => {
        const { classification, ...res } = product;
        return res;
      });

      const providerForm = {
        product: products,
        paymentMode: values.paymentMode,
        validityQuoteDate: values.validityQuoteDate,
      };

      setIsLoadingSend(true);
      jachetService
        .putQuoteRequestArticlesByProviderByEmail(providerId, providerForm)
        .then((response) => {
          setIsLoadingSend(false);
          toast.success(
            "Les informations renseignent par le fournisseur on bien été prise en compte."
          );
          setConfirmation("ACCEPTED");
          setConfirmationStep(true);
        })
        .catch((error) => {
          setIsLoadingSend(false);
          toast.warn(`${error?.data.message}`);
        });
    }
  };
  const registerWithStatusRefuse = (values, actions) => {
    if (providerId) {
      setIsLoadingRefuse(true);
      jachetService
        .getQuoteRequestChangeStatusWithPublic(providerId, "REFUSED")
        .then(() => {
          setIsLoadingRefuse(false);
          setConfirmation("REFUSED");
          setConfirmationStep(true);
        })
        .catch((error) => {
          setIsLoadingRefuse(false);
          toast.warn(`${error?.data.message}`);
        });
    }
  };
  const getDemandeDevisInformation = (providerId, setFieldValue) => {
    if (providerId) {
      setIsLoading(true);
      jachetService
        .getQuoteRequestArticlesByProviderByEmail(providerId)
        .then((quoteRequest) => {
          setDemandeDevis(quoteRequest);
          const fields = ["product", "deliveryDate"];

          fields.forEach((field) =>
            setFieldValue(field, quoteRequest[field], false)
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={registerWithStatusSend}>
      {({ handleChange, setFieldValue, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          if (providerId) {
            getDemandeDevisInformation(providerId, setFieldValue);
          }
        }, [setFieldValue]);

        return (
          <>
            <CssBaseline />
            <div
              style={{
                position: "relative",
                top: "5rem",
                paddingLeft: "16rem",
                paddingRight: "16rem",
              }}
            >
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "30px",
                            color: "#454e99",
                          }}
                        >
                          Formulaire de soumission
                        </div>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <div>
                          {!confirmationStep &&
                          demandeDevis &&
                          !demandeDevis.ansered ? (
                            <>
                              {isloading ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: 20,
                                  }}
                                >
                                  <img
                                    src={LoadingImport}
                                    alt=""
                                    width={100}
                                    height={100}
                                  />
                                </div>
                              ) : (
                                <Form noValidate autoComplete="off">
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item md={6} xs={12}>
                                        <label className="label-color">
                                          La date de livraison souhaitée
                                        </label>
                                        <DatePickerField
                                          placeholder="JJ/MM/AAAA"
                                          name="deliveryDate"
                                          format="dd/MM/yyyy"
                                          views={["date", "month", "year"]}
                                          fullWidth
                                        />
                                      </Grid>

                                      <Grid item md={6} xs={12}>
                                        <label className="label-color">
                                          La date de livraison possible
                                        </label>

                                        <DatePickerField
                                          placeholder="JJ/MM/AAAA"
                                          name="validityQuoteDate"
                                          format="dd/MM/yyyy"
                                          views={["date", "month", "year"]}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item md={12} xs={12}>
                                        <label className="label-color">
                                          Mode de paiement
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          native
                                          variant="outlined"
                                          fullWidth
                                          required
                                          color="secondary"
                                          name="paymentMethod"
                                          onChange={handleChange}
                                          value={values.paymentMethod}
                                          defaultValue=""
                                          IconComponent={() => (
                                            <ExpandMore className="input-select-dropDownIcon mr-5 label-color" />
                                          )}
                                        >
                                          <option value="" disabled>
                                            Mode de paiement
                                          </option>

                                          {PAIEMENT_MODE.map(
                                            (paiement, index) => (
                                              <option
                                                value={paiement.value}
                                                key={index}
                                              >
                                                {paiement.display}
                                              </option>
                                            )
                                          )}
                                        </Select>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classes.wraaperStycky}>
                                    <FieldArray
                                      name="product"
                                      render={(arrayHelpers) => (
                                        <>
                                          {renderingNewlyAddedComponent(
                                            values,
                                            arrayHelpers,
                                            handleChange,
                                            classes
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      margin: "20px auto",
                                    }}
                                  >
                                    <div>
                                      <Button
                                        onClick={() =>
                                          registerWithStatusRefuse(values)
                                        }
                                        className="red-button"
                                        disabled={isLoadingRefuse}
                                        startIcon={
                                          isLoadingRefuse ? (
                                            <CircularProgress size={24} />
                                          ) : null
                                        }
                                      >
                                        Refuser
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        type="submit"
                                        className="violet-button"
                                        disabled={isLoadingSend}
                                        startIcon={
                                          isLoadingSend ? (
                                            <CircularProgress size={24} />
                                          ) : null
                                        }
                                      >
                                        Envoyer
                                      </Button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  marginTop: 25,
                                }}
                              >
                                {confirmaton && confirmaton !== "REFUSED" ? (
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      width: "420px",
                                      textAlign: "center",
                                      margin: "auto",
                                      fontWeight: 300,
                                    }}
                                  >
                                    Vos informations renseignées ont bien été
                                    prises en compte, nous vous remercions.
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: "25px",
                                      width: "420px",
                                      textAlign: "center",
                                      margin: "auto",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    Votre réponse a bien été prise en compte,
                                    nous vous remercions.
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ProviderFormSubmission;
