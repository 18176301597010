import { CircularProgress, Grid } from "@material-ui/core";

const styleCircle = {
  width: 25,
  height: 25,
  position: "relative",
  top: 5,
  right: 15,
};

const LoginForm = ({
  handleSubmitLogin,
  handleChangeLoginContent,
  handleCheckboxContent,
  isExist,
}) => {
  return (
    <form onSubmit={handleSubmitLogin}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <input
            type="text"
            name="login"
            onChange={handleChangeLoginContent}
            className="w-full h-48 mb-8 mt-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
            placeholder="--- Veuillez entrer votre username ---"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <input
            type="password"
            name="password"
            onChange={handleChangeLoginContent}
            className="w-full h-48 px-3 mt-6 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sizeAndBorder"
            placeholder="--- Veuillez entrer votre mot de passe ---"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container className="mt-10">
            <Grid item md={6} xs={6}>
              <label htmlFor="remember" className="text-xs">
                <input
                  id="remember"
                  name="remember"
                  onChange={handleCheckboxContent}
                  type="checkbox"
                  className="mr-6"
                />
                Se souvenir de moi
              </label>
            </Grid>

            <Grid item md={6} xs={6} className="flex justify-end">
              <Link to="#">Mot de passe oublié ?</Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <button
            type="submit"
            onClick={handleSubmitLogin}
            disabled={isExist}
            className="submitStyle rounded mt-24"
          >
            {isExist ? <CircularProgress style={styleCircle} /> : null}
            <span>Connexion</span>
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
