import promise from "promise";
import ApiPgfService from "app/core/services/api/api-pgf/api-pgf.service";

export default class BuyService {
  constructor() {
    this._api = new ApiPgfService();
    this.endPoint = {
      provider: "providers",

      quoteRequest: "quotes-request",
      command: "internal-commands",
      internalDeliveryNote: "internal-delivery-notes",
      internalInvoice: "internal-invoices",
      expenseReports: "expense-reports",
    };
  }

  /**
   * Change statut outside app
   *
   */

  getQuoteRequestChangeStatusWithPublic(quoteRequestId, status) {
    return this._api
      ._get(`v1/quotes-public/${quoteRequestId}/change-status/${status}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putQuoteRequestArticlesByProviderByEmail(quoteRequestId, data) {
    return this._api
      ._put(`v1/quotes-request-public/${quoteRequestId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuoteRequestArticlesByProviderByEmail(quoteRequestId) {
    return this._api
      ._get(`v1/quotes-request-public/${quoteRequestId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Demande de devis module
   *
   */

  getQuotesRequest(budgetaryExerciceId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.quoteRequest}/exercise/${budgetaryExerciceId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuoteRequest(quoteRequestId) {
    return this._api
      ._get(`v1/${this.endPoint.quoteRequest}/${quoteRequestId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuoteRequestByEmail(data) {
    return this._api
      ._post(`v1/${this.endPoint.quoteRequest}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuoteRequestHistoryByReference(budgetaryExerciceId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.quoteRequest}/exercise/${budgetaryExerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getQuoteRequestChangeStatus(status, data) {
    return this._api
      ._post(`v1/${this.endPoint.quoteRequest}/change-status/${status}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postQuoteRequestDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.quoteRequest}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postQuoteRequest(data) {
    return this._api
      ._post(`v1/${this.endPoint.quoteRequest}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putQuoteRequest(quoteRequestId, data) {
    return this._api
      ._put(`v1/${this.endPoint.quoteRequest}/${quoteRequestId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putQuoteRequestDraft(quoteRequestId, data) {
    return this._api
      ._put(`v1/${this.endPoint.quoteRequest}/${quoteRequestId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteQuoteRequest(quoteRequestId) {
    return this._api
      ._delete(`v1/${this.endPoint.quoteRequest}/${quoteRequestId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getCommandsSelectedToCreateAnOrder(exerciseId) {
    return this._api
      ._get(`v1/${this.endPoint.quoteRequest}/exercise/${exerciseId}/next-step`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Bon de commande module
   *
   */

  postInternalCommand(data) {
    return this._api
      ._post(`v1/${this.endPoint.command}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalCommandDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.command}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalCommandDraft(commandDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.command}/${commandDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommands(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.command}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommandNextStep(exerciseId) {
    return this._api
      ._get(`v1/${this.endPoint.command}/exercise/${exerciseId}/next-step`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommandPreviewByQuoteRequest(quoteRequestId) {
    return this._api
      ._get(`v1/${this.endPoint.command}/preview/from/quote/${quoteRequestId}`)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommandReferenceCode(exerciseId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.command}/exercise/${exerciseId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommand(commandId) {
    return this._api
      ._get(`v1/${this.endPoint.command}/${commandId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalCommand(commandId, data) {
    return this._api
      ._put(`v1/${this.endPoint.command}/${commandId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteInternalCommand(commandId) {
    return this._api
      ._delete(`v1/${this.endPoint.command}/${commandId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalCommandSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.command}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommandTranformInQuoteRequest(quoteId) {
    return this._api
      ._get(`v1/${this.endPoint.command}/preview/from/quote/${quoteId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalCommandSetPrices(commandId, data) {
    return this._api
      ._put(`v1/${this.endPoint.command}/${commandId}/set-prices`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalCommandChangeStatus(newStatus, data) {
    return this._api
      ._post(`v1/${this.endPoint.command}/change-status/${newStatus}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Bon de livraison module
   *
   */

  getInternalDeliveryNotes(budgetaryExerciceId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/exercise/${budgetaryExerciceId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalCommandSelectedToCreateInternalDeliveryNote(budgetaryExerciceId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/exercise/${budgetaryExerciceId}/next-step`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalDeliveryNoteHistoryByReference(
    budgetaryExerciceId,
    referenceCode
  ) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/exercise/${budgetaryExerciceId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalDeliveryNote(InternalDeliveryNoteId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalDeliveryNote(data) {
    return this._api
      ._post(`v1/${this.endPoint.internalDeliveryNote}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalDeliveryNoteDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.internalDeliveryNote}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalDeliveryNote(InternalDeliveryNoteId, data) {
    return this._api
      ._put(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalDeliveryNoteTranformInOrderReceiptDelivery(commandId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/preview/from/command/${commandId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalDeliveryNoteDraft(InternalDeliveryNoteId, data) {
    return this._api
      ._put(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}/draft`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteInternalDeliveryNote(InternalDeliveryNoteId) {
    return this._api
      ._delete(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInvoicesAttachedToInternalDeliveryNote(InternalDeliveryNoteId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}/linked-invoice`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalDeliveryNotePreviewByCommand(params, commandId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/preview/from/${params}/${commandId}`
      )
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getTransformsInternalDeliveryNoteCommandObjetFormat(InternalDeliveryNoteId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalDeliveryNote}/${InternalDeliveryNoteId}/format-command`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalDeliveryNoteChangeStatus(status, data) {
    return this._api
      ._post(
        `v1/${this.endPoint.internalDeliveryNote}/change-status/${status}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Global road
   *
   */
  postReferenceCodeEntred(moduleName, data) {
    return this._api
      ._post(`v1/${moduleName}/last-number`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getReferenceCodeIsFirst(moduleName, exerciseId) {
    return this._api
      ._get(`v1/${moduleName}/exercise/${exerciseId}/is-first`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getUsedSystemReferenceCode(moduleName, exerciseId) {
    return this._api
      ._get(`v1/${moduleName}/exercise/${exerciseId}/use-default`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Facture module
   *
   */

  postInternalInvoice(data) {
    return this._api
      ._post(`v1/${this.endPoint.internalInvoice}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalInvoiceDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.internalInvoice}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalInvoiceChangeStatus(newStatus, data) {
    return this._api
      ._post(
        `v1/${this.endPoint.internalInvoice}/change-status/${newStatus}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalInvoiceDraft(invoiceDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.internalInvoice}/${invoiceDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoiceReferenceCode(exerciseId, referenceCode) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalInvoice}/exercise/${exerciseId}/reference-code/${referenceCode}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoice(invoiceId) {
    return this._api
      ._get(`v1/${this.endPoint.internalInvoice}/${invoiceId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoices(exerciseId, params) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalInvoice}/exercise/${exerciseId}`,
        params
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalInvoice(invoiceId, data) {
    return this._api
      ._put(`v1/${this.endPoint.internalInvoice}/${invoiceId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putInternalInvoiceAddPayment(invoiceId, data) {
    return this._api
      ._put(
        `v1/${this.endPoint.internalInvoice}/${invoiceId}/add-payment`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoiceToPay(invoiceId) {
    return this._api
      ._get(`v1/${this.endPoint.internalInvoice}/${invoiceId}/pay`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoicesPreviewByDeliveryNotes(InternalDeliveryNoteId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalInvoice}/preview/from/delivery_note/${InternalDeliveryNoteId}`
      )
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postInternalInvoice(data) {
    return this._api
      ._post(`v1/${this.endPoint.internalInvoice}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteInternalInvoice(invoiceId) {
    return this._api
      ._delete(`v1/${this.endPoint.internalInvoice}/${invoiceId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getInternalInvoicePreviewFromItemsSelected(params, deliveryNoteId) {
    return this._api
      ._get(
        `v1/${this.endPoint.internalInvoice}/preview/from/${params}/${deliveryNoteId}`
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  /**
   * Note de frais module
   *
   */

  putExpenseReportFiles(expenseId, data) {
    return this._api
      ._put(`v1/${this.endPoint.expenseReports}/${expenseId}/files`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putExpenseReportDraft(expenseDraftId, data) {
    return this._api
      ._put(`v1/${this.endPoint.expenseReports}/${expenseDraftId}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postExpenseReportDraft(data) {
    return this._api
      ._post(`v1/${this.endPoint.expenseReports}/draft`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postExpenseReportSend(data) {
    return this._api
      ._post(`v1/${this.endPoint.expenseReports}/send`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  deleteExpenseReport(expenseId) {
    return this._api
      ._delete(`v1/${this.endPoint.expenseReports}/${expenseId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postExpenseReportChangeStatus(newStatus, data) {
    return this._api
      ._post(
        `v1/${this.endPoint.expenseReports}/change-status/${newStatus}`,
        data
      )
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postExpenseReportFiles(expenseId, data) {
    return this._api
      ._post(`v1/${this.endPoint.expenseReports}/${expenseId}/files`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  putExpenseReport(expenseId, data) {
    return this._api
      ._put(`v1/${this.endPoint.expenseReports}/${expenseId}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getExpenseReport(expenseId) {
    return this._api
      ._get(`v1/${this.endPoint.expenseReports}/${expenseId}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  postExpenseReports(data) {
    return this._api
      ._post(`v1/${this.endPoint.expenseReports}`, data)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
  getExpenseReports(exerciseId, params) {
    return this._api
      ._get(`v1/${this.endPoint.expenseReports}/exercise/${exerciseId}`, params)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }

  getProviders() {
    return this._api
      ._get(`v1/${this.endPoint.provider}`)
      .then((response) => response.data)
      .then((response) => response.data)
      .catch((error) => promise.reject(error));
  }
}
