const PaymentMode = {
  CREDIT_CARD: "Carte de credit",
  CHEQUE: "Chèque",
  CASH: "Espece",
  MOBILE_PAYMENT: "Mobile money",
  DEBIT: "Virement",
};

class DisplayPaymentMode {
  getPayment = (payment) => {
    let currentPaymentMode;
    for (const obj in PaymentMode) {
      if (payment === obj) {
        currentPaymentMode = PaymentMode[obj];
      }
    }
    return currentPaymentMode;
  };
}

export default DisplayPaymentMode;
