import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  CssBaseline,
  Container,
  makeStyles,
  Grid,
  Paper,
} from "@material-ui/core";

import LoadingImport from "app/shared/assets/images/icons/spinner.gif";
import SellService from "app/core/services/budgetary-exercise/je-vends/je-vends.service";
import useRouter from "app/hooks/useRouter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 450,
    padding: 20,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const NotificationEmail = () => {
  const jeVendsService = new SellService();

  const classes = useStyles();
  const router = useRouter();
  const { moduleName, moduleNameId, status } = router.query;

  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState("");

  useEffect(() => {
    confirmActionEmail();
  }, []);

  const confirmActionEmail = () => {
    if (moduleName && moduleName === "quote") {
      setIsLoading(true);
      setNotification("Traitement en cours...");

      jeVendsService
        .getChangeStatusQuotesWithPublic(moduleNameId, status.toUpperCase())
        .then((res) => {
          setIsLoading(false);
          setNotification("Reponse enregistrée.");
        })
        .catch((error) => {
          setIsLoading(false);
          toast.warn(
            "Une erreur est survenue lors de la confirmation de votre devis, veuillez ressayer s'il vous plaît."
          );
        });
    }
    if (moduleName && moduleName === "command") {
      setIsLoading(true);
      setNotification("Traitement en cours...");

      jeVendsService
        .getCommandChangeStatusWithPublic(moduleNameId, status.toUpperCase())
        .then((res) => {
          setNotification("Reponse enregistrée.");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.warn(
            "Une erreur est survenue lors de la confirmation du bon de commande, veuillez ressayer s'il vous plaît."
          );
        });
    }
    if (moduleName && moduleName === "delivery_note") {
      setIsLoading(true);
      setNotification("Traitement en cours...");

      jeVendsService
        .getLivraisonsChangeStatusWithPublic(moduleNameId, status.toUpperCase())
        .then((res) => {
          setNotification("Reponse enregistrée.");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.warn(
            "Une erreur est survenue lors de la confirmation du bon de livraison, veuillez ressayer s'il vous plaît."
          );
        });
    }
    if (moduleName && moduleName === "invoice") {
      setIsLoading(true);
      setNotification("Traitement en cours...");

      jeVendsService
        .getFactureChangeStatusWithPublic(moduleNameId, status.toUpperCase())
        .then((res) => {
          setNotification("Reponse enregistrée.");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.warn(
            "Une erreur est survenue lors de la confirmation de la facture, veuillez ressayer s'il vous plaît."
          );
        });
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" style={{ position: "relative", top: "15rem" }}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <div style={{ textAlign: "center", fontSize: "20px" }}>
                    {notification}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      textAlign: "center",
                      position: "relative",
                      top: "6rem",
                    }}
                  >
                    {isLoading ? (
                      <img
                        src={LoadingImport}
                        alt="loading..."
                        width={150}
                        height={150}
                      />
                    ) : (
                      <p
                        style={{
                          fontSize: "25px",
                          fontFamily: "Mada",
                          marginTop: "6rem",
                        }}
                      >
                        Votre réponse a bien été enregistrée, Nous vous
                        remercions.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NotificationEmail;
