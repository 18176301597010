export const FORMAT_DATE_MOMENT = "YYYY-MM-DD";
export const FORMAT_DAY_MOMENT = "DD";
export const FORMAT_YEAR_MOMENT = "YYYY";
export const FORMAT_DATE_MOMENT_FR = "DD/MM/YYYY";
export const FORMAT_DATE_MOMENT_FR_2 = "DD-MM-YYYY";
export const OPERATION_FORMAT_DATE_MOMENT = "YYYY-MM-DDTHH:mmZZ";
export const MAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const CUSTOMERS = [
  { value: "client-1", display: "CLIENT-1" },
  { value: "client-2", display: "CLIENT-2" },
  { value: "client-3", display: "CLIENT-3" },
];

export const GLOBAL_REGIME = [
  { value: "regime-1", display: "REGIME-1" },
  { value: "regime-3", display: "REGIME-2" },
  { value: "regime-3", display: "REGIME-3" },
];

export const ACCOUNT_TYPE = [
  { value: "courant", display: "COMPTE COURANT" },
  { value: "epargne", display: "COMPTE EPARGNE" },
  { value: "entreprise", display: "COMPTE ENTREPRISE" },
];

export const PAIEMENT_MODE = [
  { value: "CASH", display: "espèce" },
  { value: "CHEQUE", display: "chèque" },
  {
    value: "CREDIT_CARD",
    display: "Carte de crédit",
  },
  { value: "DEBIT", display: "Prélèvement" },
  {
    value: "MOBILE_PAYMENT",
    display: "Paiement Mobile",
  },
];
export const REMUNERATED = [
  { value: "YES", display: "OUI" },
  { value: "NO", display: "NON" },
];

export const REIMBURSABLE = [
  { value: "YES", display: "OUI" },
  { value: "NO", display: "NON" },
];

export const REMBOURSEMENT_METHOD = [
  { value: "MERCHANDISES", display: "Remplacement de produit" },
  { value: "CASH_REDEEMED", display: "Remboursement en numéraire" },
];

export const ACOMPTE_TYPE = [
  { value: "MONTANT", display: "Montant", reference: "Montant" },
  { value: "POURCENTAGE", display: "Pourcentage", reference: "Pourcentage" },
];

export const CARD_TYPE = [
  { value: "MASTER_CARD", display: "master carte" },
  { value: "VISA", display: "visa" },
];

export const PERIODICITY = [
  { value: "DAILY", display: "QUOTIDIEN", valueConversion: "days" },
  { value: "WEEKLY", display: "HEBDOMADAIRE", valueConversion: "weeks" },
  { value: "MONTHLY", display: "MENSUEL", valueConversion: "months" },
  {
    value: "TRIMESTRIAL",
    display: "TRIMESTRIEL",
    valueConversion: "trimestrial",
  },
  { value: "SEMESTRIAL", display: "SEMESTRIEL", valueConversion: "semestrial" },
  { value: "ANNUAL", display: "ANNUEL", valueConversion: "years" },
];

export const DURATION_TYPE = [
  { value: "DAILY", display: "JOUR" },
  { value: "MONTHLY", display: "MOIS" },
  { value: "ANNUAL", display: "ANNÉE" },
];

export const REIMBURSEMENT_PERIODICITY = [
  { value: "DAILY", display: "QUOTIDIEN" },
  { value: "WEEKLY", display: "HEBDOMADAIRE" },
  { value: "MONTHLY", display: "MENSUEL" },
  { value: "TRIMESTRIAL", display: "TRIMESTRIEL" },
  { value: "SEMESTRIAL", display: "SEMESTRIEL" },
  { value: "ANNUAL", display: "ANNUEL" },
];

export const FACTURES = [
  { value: "FACTURE-001", display: "FACTURE-001" },
  { value: "FACTURE-002", display: "FACTURE-002" },
  { value: "FACTURE-003", display: "FACTURE-003" },
];

export const QUANTITY = [
  { value: "KG", display: "kg" },
  { value: "G", display: "g" },
  { value: "L", display: "l" },
  { value: "U", display: "u" },
];
export const PRICE = [
  { value: "TTC", display: "ttc" },
  { value: "HT", display: "ht" },
];
export const DISCOUNT = [
  { value: "POURCENTAGE", display: "%" },
  { value: "MONTANT", display: "montant" },
];

export const CIVILITIES = [
  { value: "Madame", display: "Mme" },
  { value: "Mademoiselle", display: "Mlle" },
  { value: "Monsieur", display: "M" },
];

export const ACCOUNTS_CHARTS = [
  { value: "ACCOUNTINGFIRM", display: "Modèle Client" },
  { value: "CUSTOMER", display: "Modèle Cabinet" },
  { value: "DEFAULT", display: "Ex nihilo" },
];
export const LOAN_TYPE = [
  { value: "ORDINAIRE", display: "ORDINAIRE" },
  { value: "CREDIT_BAIL", display: "CREDIT BAIL" },
];
export const TYPE_APPORTS = [
  { value: "LIBERATION_CAPITAL", display: "LIBERATION CAPITAL" },
  { value: "AUGMENTATION_CAPITAL", display: "AUGMENTATION CAPITAL" },
];

export const TERM_TYPE = [
  { value: "FIXED", display: "FIXE" },
  { value: "VARIABLE", display: "VARIABLE" },
];

export const TYPE_CREDITOR = [
  { value: "PHYSICAL_PERSON", display: "PHYSIQUE" },
  { value: "ENTERPRISE", display: "MORALE" },
];

export const ACCOUNTANT_PLAN_TITLE = [
  {
    id: 1,
    label: "GENERAL",
  },
  {
    id: 2,
    label: "1 - CAPITAUX",
  },
  {
    id: 3,
    label: "2 - IMMOBILISATIONS",
  },
  {
    id: 4,
    label: "3 - STOCKS",
  },
  {
    id: 5,
    label: "4 - FOURNISSEURS",
  },
  {
    id: 6,
    label: "4 - CLIENTS",
  },
  {
    id: 7,
    label: "4 - AUTRES TIERS",
  },
  {
    id: 8,
    label: "5 - FINANCIERS",
  },
  {
    id: 9,
    label: "6 - CHARGES",
  },
  {
    id: 10,
    label: "7 - PRODUITS",
  },
];

export const ACCOUNTANT_TERM = [
  {
    id: 1,
    label: "GENERAL",
  },
  {
    id: 2,
    label: "CAPITAL",
  },
  {
    id: 3,
    label: "IMMOBILISATION",
  },
  {
    id: 4,
    label: "STOCK",
  },
  {
    id: 5,
    label: "FOURNISSEUR",
  },
  {
    id: 6,
    label: "CLIENT",
  },
  {
    id: 7,
    label: "AUTRE_TIER",
  },
  {
    id: 8,
    label: "FINANCIER",
  },
  {
    id: 9,
    label: "CHARGE",
  },
  {
    id: 10,
    label: "PRODUIT",
  },
];

export const DISPLAY_TYPE = [
  { value: "simple", display: "Annuel" },
  { value: "multiple", display: "Pluriannuel" },
];

export const DISPLAY_PRICE = [
  { value: "fcfa", display: "FCFA" },
  { value: "euro", display: "EURO" },
];

export const DISPLAY_MODULES = [
  { value: "quote", display: "DEVIS" },
  { value: "command", display: "BON DE COMMANDE" },
  { value: "deliveryNote", display: "BON DE LIVRAISON" },
  { value: "invoice", display: "FACTURE" },
];
export const DISPLAY_MODULES_2 = [
  { value: "quote", reference: "DEVIS" },
  { value: "command", reference: "BON DE COMMANDE" },
  { value: "deliveryNote", reference: "BON DE LIVRAISON" },
  { value: "invoice", reference: "FACTURE" },
];

export const ASK = [
  { value: "non", reference: "NO" },
  { value: "oui", reference: "YES" },
];
export const MONTHS = [
  { value: "janvier", display: "JANVIER", currentValue: "janvier" },
  { value: "fevrier", display: "FEVRIER", currentValue: "fevrier" },
  { value: "mars", display: "MARS", currentValue: "mars" },
  { value: "avril", display: "AVRIL", currentValue: "avril" },
  { value: "mai", display: "MAI", currentValue: "mai" },
  { value: "juin", display: "JUIN", currentValue: "juin" },
  { value: "juillet", display: "JUILLET", currentValue: "juillet" },
  { value: "aout", display: "AOUT", currentValue: "aout" },
  { value: "septembre", display: "SEPTEMBRE", currentValue: "septembre" },
  { value: "octobre", display: "OCTOBRE", currentValue: "octobre" },
  { value: "novembre", display: "NOVEMBRE", currentValue: "novembre" },
  { value: "decembre", display: "DECEMBRE", currentValue: "decembre" },
];
export const MONTHS_WITH_INDEX = [
  { month: "janvier", id: "01" },
  { month: "fevrier", id: "02" },
  { month: "mars", id: "03" },
  { month: "avril", id: "04" },
  { month: "mai", id: "05" },
  { month: "juin", id: "06" },
  { month: "juillet", id: "07" },
  { month: "aout", id: "08" },
  { month: "septembre", id: "09" },
  { month: "octobre", id: "10" },
  { month: "novembre", id: "11" },
  { month: "decembre", id: "12" },
];
export const SEMESTRIEL_MONTHS_INDEX = [
  { month: "janvier - juin", id: "01" },
  { month: "juillet - decembre", id: "02" },
];
export const TRIMESTRIEL_MONTHS_INDEX = [
  { month: "janvier - mars", id: "01" },
  { month: "avril - juin", id: "02" },
  { month: "juillet - septembre", id: "03" },
  { month: "octobre - decembre", id: "04" },
];

export const SEMESTRIEL_MONTHS = [
  {
    value: "janvier_fevrier_mars_avril_mai_juin",
    display: "JANVIER-JUIN",
    currentValue: "janvier - juin",
  },
  {
    value: "juillet_aout_septembre_octobre_novembre_decembre",
    display: "JUILLET-DECEMBRE",
    currentValue: "juillet - decembre",
  },
];
export const TRIMESTRIEL_MONTHS = [
  {
    value: "janvier_fevrier_mars",
    display: "JANVIER-MARS",
    currentValue: "janvier - mars",
  },
  {
    value: "avril_mai_juin",
    display: "AVRIL-JUIN",
    currentValue: "avril - juin",
  },
  {
    value: "juillet_aout_septembre",
    display: "JUILLET-SEPTEMBRE",
    currentValue: "juillet - septembre",
  },
  {
    value: "octobre_novembre_decembre",
    display: "OCTOBRE-DECEMBRE",
    currentValue: "octobre - decembre",
  },
];
export const DISPLAY_TYPE_MONTHS = [
  { value: "month", display: "MENSUEL" },
  { value: "trimester", display: "TRIMESTRIEL" },
  { value: "semester", display: "SEMESTRIEL" },
];

export const BUDGET_HEADERS = [
  { budgetType: "achat", display: "ACHAT" },
  { budgetType: "vente", display: "VENTE" },
  { budgetType: "salaire", display: "SALAIRE" },
  { budgetType: "investissement", display: "INVESTISSEMENT" },
];
export const AVAILABLE_EXTENSION = [
  "image/png, image/svg, image/jpg, image/jpeg, image/svg+xml, application/pdf ",
];

export const STATUS_AGREEMENT = {
  BROUILLON: "Brouillon",
  SAVED: "Enregistrée",
  SENT: "Envoyée",
  ACCEPTED: "Acceptée",
  REFUSED: "Refusée",
  ORDERED: "Ordonnée",
  DELIVERED: "BL",
  INVOICED: "Facturée",
  PAID: "Payée",
  UNPAID: "Impayée",
  RESPONSE: "Reponse",
};
export const MIN_DATE_MSG =
  "La date renseignée doit être comprise entre la date de début et de fin de l'exercice en cours.";
export const MAX_DATE_MSG =
  "La date renseignée doit être comprise entre la date de début et de fin de l'exercice en cours.";

export const QUOTE = "quote";
export const COMMAND = "command";
export const DELIVERY_NOTE = "deliveryNote";
export const INVOICE = "invoice";
export const QUOTE_REQUEST = "quoteRequest";
export const INTERNAL_COMMAND = "internalCommand";
export const INTERNAL_DELIVERY = "internalDeliveryNote";
export const INTERNAL_INVOICE = "internalInvoice";
export const ADVANCE = "advance";
export const EXPENSE_REPORTS = "expenseReport";
export const CREDIT = "credit";
export const SEARCH_TYPE = "AUTOCOMPLETION";
export const LOAN = "loan";
export const CAPITAL_CONTRIBUTION = "capitalContribution";
export const CCA_CONTRIBUTION = "ccaContribution";
export const GRANT = "grant";
export const BUDGETARY_LINE = "budgetaryLine";
export const BUDGETARY_ASSOMPTIONS = "budgetaryAssumption";

export const ACTIONS_TABLES = {
  Others: [
    {
      name: "Refuser",
      actions: "REFUSED",
    },
    {
      name: "Accepter",
      actions: "ACCEPTED",
    },
  ],
  Facture: [
    {
      name: "Payer",
      actions: "PAID",
    },
    {
      name: "Impayer",
      actions: "UNPAID",
    },
  ],
};
export const DEFAULT_VALUES = {
  designation: null,
  quantityNumber: 0,
  quantityType: "KG",
  unitPriceValue: 0,
  unitPriceType: "HT",
  discountType: "POURCENTAGE",
  discountValue: "",
  tvaRate: 0,
  classification: null,
  description: null,
};

export const CASHIN_STATUS = {
  BEING_PROCESSED: "En procédure",
  CASHIERED: "Encaissé",
  RELAUNCHED: "Relancé",
  ACCEPTED: "Accepté",
  IN_PAIN: "En souffrance",
  UNCASHIERED: "Non encaissé",
  WAITING: "En attente",
  UNPAID: "Non remboursé",
  PAID: "Remboursé",
  SAVED: "Enregistré",
};

export const PROVIDER_FIELDS = [
  "address",
  "businessName",
  "phoneNumber",
  "siret",
  "email",
];

export const CLIENT_FIELDS = [
  "clientName",
  "clientNumber",
  "clientAddress",
  "clientEmail",
];

export const DISPLAY_PAYMENT_MODE = {
  CREDIT_CARD: "Carte de credit",
  CHEQUE: "Chèque",
  CASH: "Espece",
  MOBILE_PAYMENT: "Mobile money",
  DEBIT: "Virement",
};
